import React, { useState } from "react";
import DateCountdown from 'react-date-countdown-timer';

import cx from "classnames";


export default () => {

  return (
    <header className="container px-4 mx-auto py-2">
      <div className="flex flex-row justify-between items-center relative">

          <img
            src="/images/full-logo.png"
            width="80"

          />

      </div>
     
      <div className="text-white text-right">
        
      <DateCountdown className="text-white" dateTo='April 21, 2022 22:00 GMT+08:00'   /> <span className="text-yellow-300" >{" "} To Launch </span>
      </div> 

    </header>
    
  );
};
