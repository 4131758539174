import React from "react";

export default () => {
  return (
    
    <div className="py-8 ">
      <footer className="container mx-auto px-4">
        <div className="footer-copyright text-center py-3 text-white">
        <div fluid>
          &copy; {new Date().getFullYear()} Copyright: <a href="#"> Roi Global Limited </a>
        </div>

      </div>
          

      </footer>
    </div>
  );
};
