
import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/common/Button";
import Modal from "../components/common/Modal";
import Card from "../components/common/Card";
import Card1 from "../components/common/Card1";
import Card2 from "../components/common/Card2";
import Spinner from "../components/common/Spinner";
import { initWeb3 } from "../utils.js";


import fromExponential from "from-exponential";
import {Link} from "react-router-dom";
import { isNull, negate, toString } from "lodash";

import fomo from "../contracts/fomo.json";

const queryParams = new URLSearchParams(window.location.search);
var refParam = queryParams.get('ref');
if (isNull(refParam)) {
  refParam = "0x0000000000000000000000000000000000000000";    }
//console.log("ref=",refParam);

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [stakeLoading, setStakeLoading] = useState(false);

  const [withdrawLoading, setWithdrawLoading] = useState(false);
  //const [error, setError] = useState("");
  const [web3, setWeb3] = useState();
  const [accounts, setAccounts] = useState();
  const [networkId, setNetworkId] = useState();

  const [fomoContract, setfomoContract] = useState();
  const [showModal, setShowModal] = useState(false);
  const [amount0, setAmount0] = useState();
  const [amount1, setAmount1] = useState();
  const [amount2, setAmount2] = useState();
  const [amount3, setAmount3] = useState();
  const [amount4, setAmount4] = useState();
  const [amount5, setAmount5] = useState();

  const [ContractBalance, setContractBalance] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [getResult0_0, setgetResult0_0] = useState(0);
  const [getResult0_1, setgetResult0_1] = useState(0);
  const [getResult1_0, setgetResult1_0] = useState(0);
  const [getResult1_1, setgetResult1_1] = useState(0);
  const [getResult2_0, setgetResult2_0] = useState(0);
  const [getResult2_1, setgetResult2_1] = useState(0);
  const [getResult3_0, setgetResult3_0] = useState(0);
  const [getResult3_1, setgetResult3_1] = useState(0);
  const [getResult4_0, setgetResult4_0] = useState(0);
  const [getResult4_1, setgetResult4_1] = useState(0);
  const [getResult5_0, setgetResult5_0] = useState(0);
  const [getResult5_1, setgetResult5_1] = useState(0);
  const [roi0, setroi0] = useState(0);
  const [roi1, setroi1] = useState(0);
  const [roi2, setroi2] = useState(0);
  const [roi3, setroi3] = useState(0);
  const [roi4, setroi4] = useState(0);
  const [roi5, setroi5] = useState(0);
  const [UserTotalDeposits, setUserTotalDeposits] = useState(0);

  
  const [UserAvailable, setUserAvailable] = useState(0);
  const [UserReferralWithdrawn, setUserReferralWithdrawn] = useState(0);
  const [UserReferralTotalBonus, setUserReferralTotalBonus] = useState(0);
  const [UserDownlineCount, setUserDownlineCount] = useState(0);

  const [activeDeposit, setactiveDeposit] = useState([]);
  const [activeDepositInfo, setactiveDepositInfo] = useState([]);

  {/*
  getUserAvailable

  getUserReferralWithdrawn

  getUserReferralTotalBonus

  getUserDownlineCount

*/}

{/*
  const [unstakeLoading, setUnstakeLoading] = useState(false);
  const [unstakeAmount, setUnstakeAmount] = useState();
  const [referrer, setReferrer] = useState();
  const [leadStake, setLeadStake] = useState();
  const [leadToken, setLeadToken] = useState();
  const [balance, setBalance] = useState();
  const [totalSupply, setTotalSupply] = useState();
  const [stakes, setStakes] = useState();
  const [minStake, setMinStake] = useState();
  const [stakingTax, setStakingTax] = useState();
  const [unstakingTax, setUnstakingTax] = useState();
  const [registrationTax, setRegistrationTax] = useState();
  const [referralRewards, setReferralRewards] = useState();
  const [referralCount, setReferralCount] = useState();
  const [dailyROI, setDailyROI] = useState();
  const [stakingRewards, setStakeRewards] = useState();
  const [minRegister, setMinRegister] = useState();
  const [totalRewards, setTotalRewards] = useState();
*/}


 

  const switchEthereumChain = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }],
      });
    } catch (e) {
      if (e.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '56',
                chainName: 'Smart Chain',
                nativeCurrency: {
                  name: 'Binance',
                  symbol: 'BNB', // 2-6 characters long
                  decimals: 18
                },
                blockExplorerUrls: ['https://bscscan.com'],
                rpcUrls: ['https://bsc-dataseed.binance.org/'],
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      }
      // console.error(e)
    }
  };


  const init = async () => {
  

    if (isReady() ) {
      return;
    }

    setLoading(true);

    let web3;
    try {
      web3 = await initWeb3();
    } catch (err) {
      console.error(err);
      setLoading(false);
      return;
    }

    const accounts = await web3.eth.getAccounts();
    const networkId = await web3.eth.net.getId();
    console.log(networkId);


    if (networkId !== 56) {
      setNetworkId();
      setLoading(true);
      switchEthereumChain();
      setLoading(false);
      //setError("Please connect BSC Mainnet");

      //return;
    } 

    const fomoContract = new web3.eth.Contract(
      fomo.abi,
      "0x0348C4101d64BE1c4365c57D281B7Da62D5Bd764"
    ); //mainnet address for staking dapp



    const totalStaked = await fomoContract.methods.totalStaked().call();
    //console.log("...total staked" , totalStaked);
    const ContractBalance = await fomoContract.methods.getContractBalance().call();
    const getResult0 = await fomoContract.methods.getResult(0,1000000).call();
    const getResult0_0 = getResult0[0];
    const getResult0_1 = getResult0[1]/ 10000;
       //console.log("...plan 0 %" , getResult0_0);
    const getResult1 = await fomoContract.methods.getResult(1,1000000).call();
    const getResult1_0 = getResult1[0];
    const getResult1_1 = getResult1[1]/ 10000;
    const getResult2 = await fomoContract.methods.getResult(2,1000000).call();
    const getResult2_0 = getResult2[0];
    const getResult2_1 = getResult2[1]/ 10000;
    const getResult3 = await fomoContract.methods.getResult(3,1000000).call();
    const getResult3_0 = getResult3[0];
    const getResult3_1 = getResult3[1]/ 10000;
    const getResult4 = await fomoContract.methods.getResult(4,1000000).call();
    const getResult4_0 = getResult4[0];
    const getResult4_1 = getResult4[1]/ 10000;
    const getResult5 = await fomoContract.methods.getResult(5,1000000).call();
    const getResult5_0 = getResult5[0];
    const getResult5_1 = getResult5[1]/ 10000;

   
    const UserTotalDeposits = await fomoContract.methods.getUserTotalDeposits(accounts[0]).call(); 


    const UserAvailable = await fomoContract.methods.getUserAvailable(accounts[0]).call();  
    const UserReferralWithdrawn = await fomoContract.methods.getUserReferralWithdrawn(accounts[0]).call();  
    const UserReferralTotalBonus = await fomoContract.methods.getUserReferralTotalBonus(accounts[0]).call();     
    const UserDownlineCount = await fomoContract.methods.getUserDownlineCount(accounts[0]).call();  
 
       
    const UserAmountOfDeposits = await fomoContract.methods.getUserAmountOfDeposits(accounts[0]).call();



    for(var i=0;i<UserAmountOfDeposits;i++){
        activeDeposit.push(i)
    }
    setactiveDeposit(activeDeposit);
    //console.log("actd  "+ activeDeposit[0]);

    for(var i=0;i<UserAmountOfDeposits;i++){
      const info = await fomoContract.methods.getUserDepositInfo(accounts[0],i).call();
      for(var j=0;j<6;j++){
        activeDepositInfo.push(info[j]);
      };
    }
    setactiveDepositInfo(activeDepositInfo);
    //console.log("iniacdit  "+ activeDepositInfo[0]);




{/*
    const leadToken = new web3.eth.Contract(
      ERC20.abi,
      "0x2ed9e96edd11a1ff5163599a66fb6f1c77fa9c66"
    ); //mainnet address for lead token
    const totalSupply = await leadToken.methods.totalSupply().call();
    const balance = await leadToken.methods.balanceOf(accounts[0]).call();
    */}

{/*
    const leadStake = new web3.eth.Contract(
      LeadStake.abi,
      "0x86ffdd2431b78685eb32df30cdf03ec627eafcdb"
    ); //mainnet address for staking dapp

  

    const totalStaked = await leadStake.methods.totalStaked().call();
    const minStake = await leadStake.methods.minimumStakeValue().call();
    const stakingTax = await leadStake.methods.stakingTaxRate().call();
    const unstakingTax = await leadStake.methods.unstakingTaxRate().call();
    const registrationTax = await leadStake.methods.registrationTax().call();
    const referralRewards = await leadStake.methods
      .referralRewards(accounts[0])
      .call();
    const referralCount = await leadStake.methods
      .referralCount(accounts[0])
      .call();
    const dailyROI = await leadStake.methods.dailyROI().call();
    const status = await leadStake.methods.registered(accounts[0]).call();
*/}

    setWeb3(web3);
    setAccounts(accounts);
    setNetworkId(networkId);
    setfomoContract(fomoContract);
    setTotalStaked(totalStaked);
    setContractBalance(ContractBalance);

    setgetResult0_0(getResult0_0);
    setgetResult0_1(getResult0_1);
    setgetResult1_0(getResult1_0);
    setgetResult1_1(getResult1_1);
    setgetResult2_0(getResult2_0);
    setgetResult2_1(getResult2_1);
    setgetResult3_0(getResult3_0);
    setgetResult3_1(getResult3_1);
    setgetResult4_0(getResult4_0);
    setgetResult4_1(getResult4_1);
    setgetResult5_0(getResult5_0);
    setgetResult5_1(getResult5_1);

    setUserTotalDeposits(UserTotalDeposits);


    setUserAvailable(UserAvailable);
    setUserReferralWithdrawn(UserReferralWithdrawn);
    setUserReferralTotalBonus(UserReferralTotalBonus);
    setUserDownlineCount(parseInt(UserDownlineCount[0])+parseInt(UserDownlineCount[1])+parseInt(UserDownlineCount[2]));


    

{/*
    setLeadToken(leadToken);
    setTotalSupply(totalSupply);
    setBalance(balance);

    setMinStake(minStake);
    setStakingTax(stakingTax);
    setUnstakingTax(unstakingTax);
    setRegistrationTax(registrationTax);
    setReferralRewards(referralRewards);
    setReferralCount(referralCount);
    setDailyROI(dailyROI);

*/}


    window.ethereum.on("accountsChanged", (accounts) => {
      setAccounts(accounts);
    });

    setLoading(false);
  };
  

  const isReady = () => {
    if (window.ethereum ) {
    const b = !!fomoContract && !!web3 && !!window.ethereum.selectedAddress && !!networkId ;
    //console.log("isReady =",b);    
    //console.log("Contract =",fomoContract);
    //console.log("web3 =",web3);
    //console.log("accounts =",accounts);
    //console.log("networkId =",networkId);
    return (b);
    } else {return (false)}
  };

  const isAddressReady = () => {
    if (window.ethereum ) {
    const b =  !!window.ethereum.selectedAddress;
    //console.log("isAddrReady =",b);    


    return (b);
    } else {return (false)}
  };

  const isNetworkReady = () => {
    if (window.ethereum ) {
    const b =  (window.ethereum.networkVersion === "56");
    //console.log("isNetworkReady =",b);    


    return (b);
    } else {return (false)}
  };



  useEffect(() => {
    const triggerAlreadyInjectedWeb3 = async () => {
      if (window.ethereum) {

        if (
          window.ethereum.selectedAddress &&
          window.ethereum.networkVersion === "56"
        ) {
          await init();
          //console.log("...AlreadyInjectedWeb3")
        }

      }
    };
    triggerAlreadyInjectedWeb3();
  }, []);

  
  useEffect(() => {
    if (window.ethereum ) {
    window.ethereum.on('chainChanged', async () =>{

      setNetworkId(); 
      await init();
     }     
     );
    }
  }, []);


  async function updateAll() {
    await Promise.all([

      updateTotalStaked(),
      updateContractBalance(),
      updateUserTotalDeposits(),


      updateUserAvailable(),
      updateUserReferralWithdrawn(),
      updateUserReferralTotalBonus(),
      updateUserDownlineCount(),
      updateactiveDeposit(),

      {/*
      updateStakes(),
      updateTotalSupply(),
      updateAccountBalance(),
      updateStatus(),
      stakeRewards(),
      minRegisteration(),
      totalReward(),
      updateReferrals(),

    */}

    ]);
  }

 
  useEffect(() => {
    if (isReady()) {
      updateAll();
    }
  }, [fomoContract, web3, accounts, networkId]);









 
  async function updateactiveDeposit() {
    if (fomoContract) {
      const UserAmountOfDeposits = await fomoContract.methods.getUserAmountOfDeposits(accounts[0]).call();
      const activeDeposit = [];
      const activeDepositInfo = [];

      for(var i=0;i<UserAmountOfDeposits;i++){
          activeDeposit.push(i)
      }
      setactiveDeposit(activeDeposit);
      //console.log("actd  "+ activeDeposit[0]);
  
      for(var i=0;i<UserAmountOfDeposits;i++){
        const info = await fomoContract.methods.getUserDepositInfo(accounts[0],i).call();
        for(var j=0;j<6;j++){
          activeDepositInfo.push(info[j]);
        };
      }
      setactiveDepositInfo(activeDepositInfo);



      return [];
    }
  }
  


  async function updateUserAvailable() {
    if (fomoContract) {
      const UserAvailable = await fomoContract.methods.getUserAvailable(accounts[0]).call();  

      setUserAvailable(UserAvailable);

      return [];
    }
  }

  async function updateUserReferralWithdrawn() {
    if (fomoContract) {
      const UserReferralWithdrawn = await fomoContract.methods.getUserReferralWithdrawn(accounts[0]).call();  

      setUserReferralWithdrawn(UserReferralWithdrawn);

      return [];
    }
  }

  async function updateUserReferralTotalBonus() {
    if (fomoContract) {
      const UserReferralTotalBonus = await fomoContract.methods.getUserReferralTotalBonus(accounts[0]).call();  

      setUserReferralTotalBonus(UserReferralTotalBonus);

      return [];
    }
  }

  async function updateUserDownlineCount() {
    if (fomoContract) {
      const UserDownlineCount = await fomoContract.methods.getUserDownlineCount(accounts[0]).call();  

      setUserDownlineCount( parseInt(UserDownlineCount[0])+parseInt(UserDownlineCount[1])+parseInt(UserDownlineCount[2]));

      return [];
    }
  }
  


async function updateUserTotalDeposits() {
  if (fomoContract) {
    const UserTotalDeposits = await fomoContract.methods.getUserTotalDeposits(accounts[0]).call(); 

    setUserTotalDeposits(UserTotalDeposits);

    return [];
  }
}



async function updateTotalStaked() {
  if (fomoContract) {
    const totalStaked = await fomoContract.methods.totalStaked().call(); 

    setTotalStaked(totalStaked);

    return [];
  }
}

async function updateContractBalance() {
  if (fomoContract) {
    const ContractBalance = await fomoContract.methods.getContractBalance().call();

    setContractBalance(ContractBalance);

    return [];
  }
}




async function updateSetAmount0(p,v) {
  if (fomoContract){
    const roi = await fomoContract.methods.getResult(0,parseInt(v*1000000)).call();
    const r = roi[1];

        setroi0(r);
        setAmount0(v);

    }
    return [];   
}

async function updateSetAmount1(p,v) {
  if (fomoContract){
    const roi = await fomoContract.methods.getResult(1,parseInt(v*1000000)).call();
    const r = roi[1];

        setroi1(r);
        setAmount1(v);

    }
    return [];   
}

async function updateSetAmount2(p,v) {
  if (fomoContract){
    const roi = await fomoContract.methods.getResult(2,parseInt(v*1000000)).call();
    const r = roi[1];

        setroi2(r);
        setAmount2(v);

    }
    return [];   
}

async function updateSetAmount3(p,v) {
  if (fomoContract){
    const roi = await fomoContract.methods.getResult(3,parseInt(v*1000000)).call();
    const r = roi[1];

        setroi3(r);
        setAmount3(v);

    }
    return [];   
}

async function updateSetAmount4(p,v) {
  if (fomoContract){
    const roi = await fomoContract.methods.getResult(4,parseInt(v*1000000)).call();
    const r = roi[1];

        setroi4(r);
        setAmount4(v);

    }
    return [];   
}

async function updateSetAmount5(p,v) {
  if (fomoContract){
    const roi = await fomoContract.methods.getResult(5,parseInt(v*1000000)).call();
    const r = roi[1];

        setroi5(r);
        setAmount5(v);

    }
    return [];   
}






  async function stake(p,v) {
    setStakeLoading(true);

    try {
      const actual = v * 10 ** 18;
      const arg = fromExponential(actual);
      await fomoContract.methods.invest(refParam, p).send({ from: accounts[0], value: arg });

      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setShowModal(true);
      }
      console.error(err);
    }
    setStakeLoading(false);
  }



  async function withdrawEarnings() {
    if (parseFloat(UserAvailable) === 0) {
      console.error("No earnings yet!");
      setShowModal(true);
      return;
    }
    setWithdrawLoading(true);
    try {
      await fomoContract.methods.withdraw().send({ from: accounts[0] });
      await updateAll();
    } catch (err) {
      if (err.code !== 4001) {
        setShowModal(true);
      }
      console.error(err);
    }
    setWithdrawLoading(false);
  }

  function myFunction() {
    /* Get the text field */
    var copyText = document.getElementById("myInput");
  
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
  
     /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  
    /* Alert the copied text */
    alert("Copied the text: " + copyText.value);
  }
 


   function depositCard1(n) {
    var plan = "Low Risk";

     if(isNaN(activeDepositInfo[0])) {//console.log("actd empty ") 
    }
     else 
    {
    //console.log("time 1  "+ activeDepositInfo);


    const enddate = new Date(activeDepositInfo[n*6+5]*1000);
    var tdate = new Date();
   
    var td = new Date(tdate.getTime());

    //console.log(enddate + " " + td);

    if (enddate > td) {
    
    const diff = enddate - td;
    const last = new Date(diff);


    const diffInDays = Math.floor(last/(1000 * 60 * 60 * 24));
    const diffInHours = Math.floor( (last - (diffInDays*(1000 * 60 * 60 * 24)))/(1000 * 60 * 60));
    const diffInMins = Math.floor( (last - ((diffInDays*(1000 * 60 * 60 * 24))+(diffInHours*(1000 * 60 * 60)))  )
      /(1000 * 60));

    var dday =diffInDays;
    var dhour =diffInHours;
    var dmin =diffInMins;

    //console.log(" last " + diffInDays+  " last " + diffInHours+  " last "+ diffInMins);

    } else {
      var dday =0;
      var dhour =0;
      var dmin =0;

    }
    var rem = "Remain : "+ dday + " Day " + dhour + " Hour " + dmin + " Min " ;
    //console.log("rem day   "+ dday + " " +dhour+" " +dmin);

    switch (activeDepositInfo[n*6]) {
      case '0':
        plan = "Low Risk";
        break;
      case '1':
        plan = "Medium Risk";     
        break;
      case '2':
        plan = "High Risk";        
        break;
      case '3':
        plan = "Hyper Risk";       
        break;
      case '4':
        plan = "Extreme Risk";        
        break;
      case '5':
        plan = "Ultra Risk";       
        break;
      default:
        console.log(`Sorry, we are out of .`);
    }
  }

 
    return (
    <Card1 title="Invested">
    <div className="flex flex-col pt-8 pb-4 text-white">
    <div className="mb-8 text-center">
      <span className="text-yellow-300 text-2xl">
          Plan :{plan}
          </span>
      </div>
      <div className="text-center">
        <span className="text-white text-2xl">Staked :
          {(parseFloat(activeDepositInfo[n*6+2]) / 1000000000000000000).toFixed(
            3
          )}
        </span>
      </div>
      <div className="text-center">
      <span className="text-yellow-300 text-2xl">ROI :
          {(parseFloat(activeDepositInfo[n*6+3]) / 1000000000000000000).toFixed(
            3
          )} 
          </span>
      </div>
      <div className="mt-5 text-center">
      <span className="text-white text-xs">
          {rem}
          </span>
      </div>      

    </div>
    </Card1>  
    );
  
}


  return (
    <div className="w-full overflow-hidden" >
      {showModal && (
        <Modal title="" onClose={() => setShowModal(false)}>
          <div className="text-2xl mb-2">
            Error! Your transaction has been reverted!
          </div>
          <div>1. Please confirm you already connect to your Wallet and Mainnet</div>
          <div>2. Make sure you enter a Correct Value</div>
          <div>3. Please check your Account and Retry again...</div>


          <div className="flex flex-row justify-center">
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </div>
        </Modal>
      )}

      {/*
      <div className="relative z-20 w-full top-0">
        <img
          src="/images/1.png"
          alt=""
          className="absolute z-10 top-noisy"
        />
        <img
          src="/images/1.png"
          alt=""
          className="absolute z-10 second-noisy"
        />
      </div>
      */}

      <div className="relative z-10 w-full top-0">
        <div className="absolute w-full home-gradient "></div>
      </div>

      <div className="relative w-full z-30">
        <Header />

        <div className="container mx-auto pb-18 px-4 force-height ">


        <div className="grid grid-col-1 md:grid-cols-1 gap-6 mt-10 ">
        <span className="text-yellow-200 text-4xl text-center ">
        Stake Your BNB & Earn up to 20% Daily<p></p>
        </span>
        <span className="mx-18  text-center text-white text-sm "> Basic interest rate (only for new deposits): +0.3% every 24 hours <p></p>
                    </span>
        </div>  

            <div className="grid grid-col-1 md:grid-cols-2 gap-6 mt-10">
            <div className="mx-10 w-full py-6 text-center text-xs">
              <Button 
                className="hover:bg-yellow-600 w-1/2 flex flex-row items-center justify-center"
                uppercase={false}
                onClick={async () => await init()}
              >
                {loading && <Spinner color="white" size={40} />}
                {!loading && (
                  
                    ((isAddressReady()) ?  ( (isNetworkReady()) ? <p className="truncate"> {accounts} </p> : "Please connect BSC Mainnet"): "CONNECT METAMASK WALLET"  )
                )
                }
               
              </Button>
            <div className="w-full py-6 text-left text-yellow-100 leading-7 text-sm cursor-pointer flex flex-col mx-10 text-lg">
            <span className="text-white text-3xl">
                    <p></p>
                    </span>
            <a
              href="https://bscscan.com/address/0x0348C4101d64BE1c4365c57D281B7Da62D5Bd764#code"
              className="hover:text-yellow-600 font-Montserrat-ExtraBold uppercase mb-3"
            >
              Contract
            </a>
            <a href="https://t.me/+_0YwPtuYMmEwZjM1"               className="hover:text-yellow-600 font-Montserrat-ExtraBold uppercase mb-3">Telegram</a>
            <a href="https://medium.com/@automatoni/stake-your-bnb-earn-up-to-20-daily-5fc0033e1a"               className="hover:text-yellow-600 font-Montserrat-ExtraBold uppercase mb-3" >Document</a>
            <a href="https://"              className="hover:text-yellow-600 font-Montserrat-ExtraBold uppercase mb-3">Audit(Pending)</a>
            <a href="https://changenow.io/?from=btc&to=bnbbsc"              className="hover:text-yellow-600 font-Montserrat-ExtraBold uppercase mb-3">Buy BNB</a>
            <a href="https://chainlist.org/"              className="hover:text-yellow-600 font-Montserrat-ExtraBold uppercase ">Add BSC Network</a>
           </div>
           <div className="flex flex-row items-center justify-left  mx-5">
           <img src="/images/metamask-small.svg" width="46" alt="" />   
           <span className="text-yellow-200 text-lg ml-4">{"  "}</span>      
           <img src="/images/bsc.png" width="200" alt="" />
           </div>

           </div>

              <Card2 title="Total Staked">


                <div className="flex flex-col pt-8 px-2 text-white">
                  <div className="text-center pb-4">
                    <span className="text-white text-3xl">
                      {(parseFloat(totalStaked) / 1000000000000000000).toFixed(
                        3
                      )}
                    </span>
                    <span className="text-yellow-200 text-2xl ml-2">
                      BNB
                    </span>
                  </div>

                  <div className="text-center mt-8">Contract Balance :</div>
                  <div className="text-center">
                  <span className="text-white text-1xl">
                      {(parseFloat(ContractBalance) / 1000000000000000000).toFixed(
                        3
                      )}
                      </span>
                  </div>

          <div className="flex flex-row items-center justify-center mt-7">
          <img src="/images/bnb.png" width="80" alt="" />
           </div>

                </div>
              </Card2>
              </div>     
        <div className="grid grid-col-1 md:grid-cols-1 gap-6 mt-15 ">
        <span className="text-yellow-400 text-3xl text-left ">
        Choose Plan <p></p>
        </span>
        </div>              
 


        <div className="grid grid-col-1 md:grid-cols-2 gap-6 mt-3">             
                <Card title="Low Risk" >
                  <div className="flex flex-col pt-8 px-2">
                  <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Daily Earnings:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult0_0) / 10}
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Total ROI:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult0_1) }
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                  <span className="flex flex-row items-center justify-center text-yellow-200 font-bold text-3xl">
                  14 Days <img src="/images/unlocked.svg" width="30" alt="" /> 
                      </span>                      
                      <span className=" text-lg text-red-400">
                      Withdraw Any Time 
                      </span>

                      </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="Minimum 0.1 BNB"
                        value={amount0}
                        onChange={(e) => updateSetAmount0(0,e.target.value)}
                        className="text-white font-extrabold flex-shrink text-1xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => stake(0,amount0)}
                        className="hover:bg-yellow-600 flex flex-row items-center w-48 justify-center"
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            
                            <span className="w-16">INVEST</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    <div className=" p-2 flex justify-between items-center">
                    <span className="text-white text-lg">
                        ROI : {parseInt((isNaN(roi0)? 0 : roi0)) / 1000000}
                    </span>
                    </div>
                  </div>
                </Card>
              



                <Card title="Medium Risk">
                  <div className="flex flex-col pt-8 px-2">
                  <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Daily Earnings:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult1_0) / 10}
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Total ROI:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult1_1) }
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                  <span className="flex flex-row items-center justify-center text-yellow-200 font-bold text-3xl">
                  21 Days <img src="/images/unlocked.svg" width="30" alt="" /> 
                      </span>                      
                      <span className=" text-lg text-red-400">
                      Withdraw Any Time 
                      </span>

                      </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="Minimum 0.1 BNB"
                        value={amount1}
                        onChange={(e) => updateSetAmount1(1,e.target.value)}
                        className="text-white font-extrabold flex-shrink text-1xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => stake(1,amount1)}
                        className="hover:bg-yellow-600 flex flex-row items-center w-48 justify-center"
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            
                            <span className="w-16">INVEST</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    <div className=" p-2 flex justify-between items-center">
                    <span className="text-white text-lg">
                        ROI : {parseInt((isNaN(roi1)? 0 : roi1)) / 1000000}
                    </span>
                    </div>
                  </div>
                </Card>




                <Card title="High Risk">
                  <div className="flex flex-col pt-8 px-2">
                  <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Daily Earnings:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult2_0) / 10}
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Total ROI:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult2_1) }
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                  <span className="flex flex-row items-center justify-center text-yellow-200 font-bold text-3xl">
                  27 Days <img src="/images/unlocked.svg" width="30" alt="" /> 
                      </span>                      
                      <span className=" text-lg text-red-400">
                      Withdraw Any Time 
                      </span>

                      </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="Minimum 0.1 BNB"
                        value={amount2}
                        onChange={(e) => updateSetAmount2(2,e.target.value)}
                        className="text-white font-extrabold flex-shrink text-1xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => stake(2,amount2)}
                        className="hover:bg-yellow-600 flex flex-row items-center w-48 justify-center"
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            
                            <span className="w-16">INVEST</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    <div className=" p-2 flex justify-between items-center">
                    <span className="text-white text-lg">
                        ROI : {parseInt((isNaN(roi2)? 0 : roi2)) / 1000000}
                    </span>
                    </div>
                  </div>
                </Card>




                <Card title="Hyper Risk">
                  <div className="flex flex-col pt-8 px-2">
                  <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Daily Earnings:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult3_0) / 10}
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Total ROI:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult3_1) }
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                  <span className="flex flex-row items-center justify-center text-yellow-200 font-bold text-3xl">
                  14 Days <img src="/images/locked.svg" width="30" alt="" /> 
                      </span>                      
                      <span className=" text-lg text-red-200">
                      Withdraw at the End 
                      </span>

                      </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="Minimum 0.1 BNB"
                        value={amount3}
                        onChange={(e) => updateSetAmount3(3,e.target.value)}
                        className="text-white font-extrabold flex-shrink text-1xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => stake(3,amount3)}
                        className="hover:bg-yellow-600 flex flex-row items-center w-48 justify-center"
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            
                            <span className="w-16">INVEST</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    <div className=" p-2 flex justify-between items-center">
                    <span className="text-white text-lg">
                        ROI : {parseInt((isNaN(roi3)? 0 : roi3)) / 1000000}
                    </span>
                    </div>
                  </div>
                </Card>




                <Card title="Extreme Risk">
                  <div className="flex flex-col pt-8 px-2">
                  <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Daily Earnings:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult4_0) / 10}
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Total ROI:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult4_1) }
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                  <span className="flex flex-row items-center justify-center text-yellow-200 font-bold text-3xl">
                  21 Days <img src="/images/locked.svg" width="30" alt="" /> 
                      </span>                      
                      <span className=" text-lg text-red-200">
                      Withdraw at the End 
                      </span>

                      </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="Minimum 0.1 BNB"
                        value={amount4}
                        onChange={(e) => updateSetAmount4(4,e.target.value)}
                        className="text-white font-extrabold flex-shrink text-1xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => stake(4,amount4)}
                        className="hover:bg-yellow-600 flex flex-row items-center w-48 justify-center"
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            
                            <span className="w-16">INVEST</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    <div className=" p-2 flex justify-between items-center">
                    <span className="text-white text-lg">
                        ROI : {parseInt((isNaN(roi4)? 0 : roi4)) / 1000000}
                    </span>
                    </div>
                  </div>
                </Card>

                <Card title="Ultra Risk">
                  <div className="flex flex-col pt-8 px-2">
                  <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Daily Earnings:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult5_0) / 10}
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                      <span className="text-lg text-gray-400">
                      Total ROI:{" "}
                      </span>
                      <span className="text-white text-2xl">
                        {parseInt(getResult5_1) }
                      </span>
                      <span className="text-white text-2xl ml-2">%</span>
                    </div>
                    <div className="text-center pb-4">
                  <span className="flex flex-row items-center justify-center text-yellow-200 font-bold text-3xl">
                  28 Days <img src="/images/locked.svg" width="30" alt="" /> 
                      </span>                      
                      <span className=" text-lg text-red-200">
                      Withdraw at the End 
                      </span>

                      </div>
                    <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="number"
                        placeholder="Minimum 0.1 BNB"
                        value={amount5}
                        onChange={(e) => updateSetAmount5(5,e.target.value)}
                        className="text-white font-extrabold flex-shrink text-1xl w-full bg-transparent focus:outline-none focus:bg-white focus:text-black px-2"
                      />
                      <Button
                        onClick={() => stake(5,amount5)}
                        className="hover:bg-yellow-600 flex flex-row items-center w-48 justify-center"
                      >
                        {stakeLoading ? (
                          <Spinner size={30} />
                        ) : (
                          <>
                            
                            <span className="w-16">INVEST</span>{" "}
                          </>
                        )}
                      </Button>
                    </div>
                    <div className=" p-2 flex justify-between items-center">
                    <span className="text-white text-lg">
                        ROI : {parseInt((isNaN(roi5)? 0 : roi5)) / 1000000}
                    </span>
                    </div>
                  </div>
                </Card>
                </div>

        <div className="grid grid-col-1 md:grid-cols-1 gap-6 mt-15 ">
        <span className="text-yellow-400 text-4xl text-left ">
        Dashboard <p></p>
        </span>
        </div>              
 


        <div className="grid grid-col-1 md:grid-cols-2 gap-6 mt-3">   
              
              <Card2 title="Your Earnings">
                <div className="flex flex-col pt-8 px-2">
                  <div className="text-center pb-8">
                  <span className="text-gray-400 text-lg">
                          Total Invested:{" "}<p></p>
                        </span>
                    <span className="text-white text-3xl">
                      {(parseFloat(UserTotalDeposits) / 1000000000000000000).toFixed(
                        3
                      )}
                    </span>
                    <span className="text-yellow-200 text-2xl ml-2">BNB</span>
                  </div>
                  <div className="flex flex-row justify-center">
                    <Button
                      type="submit"
                      className="hover:bg-yellow-600 flex flex-row items-center justify-center w-36"
                      onClick={() => withdrawEarnings()}
                    >
                      {withdrawLoading ? (
                        <Spinner size={30} />
                      ) : (
                        <>

                          <span className="w-28">WITHDRAW</span>{" "}
                        </>
                      )}
                    </Button>
                  </div>
                  <div className="text-center text-white text-2xl mt-8 mx-2">
                    <div>
                      <div>
                        <span className="text-gray-400 text-lg">
                          Available for Withdraw:{" "}<p></p>
                        </span>
                        {(parseFloat(UserAvailable) / 1000000000000000000).toFixed(
                        3)}
                      </div>

                    </div>

                  </div>
                </div>
              </Card2>


              
              <Card2 title="Referral">
              <div className="text-left ml-8 pt-2">
                      <span className="text-lg text-gray-400">
                      Total Referral Earned:{" "}
                      </span>
                      <span className="text-white text-1xl">
                      {(parseFloat(UserReferralTotalBonus) / 1000000000000000000).toFixed(
                        3)}
                      </span>
                      
                </div>
                <div className="text-left ml-8  pt-2  ">
                      <span className=" text-lg text-gray-400">
                      Total Referral Withdrawn:{" "}
                      </span>
                      <span className="text-white text-1xl">
                      {(parseFloat(UserReferralWithdrawn) / 1000000000000000000).toFixed(
                        3)}
                      </span>
                      
                </div>
                <div className="text-left ml-8  pt-2">
                      <span className="text-lg text-gray-400">
                      Users Invited:{" "}
                      </span>
                      <span className="text-white text-1xl">
                        {UserDownlineCount}
                      </span>
                    
                </div>
                <div className="flex flex-col pt-2 px-2">
                  <div className=" pb-8">
                    <div className="text-left text-gray-400 text-lg font-thin">
                    <span className="  text-xs "> 
                    <span className="text-yellow-200 text-sm ml-8">5% {" "}</span>
                     
                    from each level 1 referral deposits <p></p>
                    <span className="text-yellow-200 text-sm ml-8">3% {" "}</span>                    
                    from each level 2 referral deposits <p></p>
                    <span className="text-yellow-200 text-sm ml-8">2% {" "}</span>
                    from each level 3 referral deposits
 
                    </span>
                    </div>
                  </div>

                  <div className="rounded-md border-2 border-primary p-2 flex justify-between items-center">
                      <input
                        type="text" 
                        value={"https://roi4bnb.com/"+"?ref="+accounts }
                        id="myInput"
                        className="text-white font-extrabold flex-shrink text-xs w-full bg-transparent focus:outline-none focus:bg-yellow-600 focus:text-black px-2"
                      />
                     <span className="text-left text-white text-xs ml-2">Invite Link</span>
                      <Button
                        onClick={() => myFunction()}
                        className="text-xs hover:bg-yellow-600 flex flex-row items-center w-12 justify-center"
                      >Copy
                      </Button>

                    </div>
                    <span className="text-left text-white text-xs ml-2">Note!{" "}You need to have at least 1 deposit to start Referral.</span>
                </div>
              </Card2>

              {activeDeposit.map((n) => (
                      
                       depositCard1(n)
                       
                      ))}


            </div>
          {/* )}  */}
        </div>
        <Footer />
      </div>
    </div>
  );
};


export default HomePage;





//<img src="/images/unlocked.svg" width="25" alt="" />